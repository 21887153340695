import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Form, Input, notification } from 'antd'
import useMutation from 'hooks/useMutation'
import { ErrorNetwork } from 'types'
import Validators from 'utils/validators'

import AuthFormContainer from 'components/atoms/AuthFormContainer'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

import SignInValidation from './SignInValidation'

export default function Login() {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [currentSignIn, setCurrentSignIn] = useState({
    email: '',
    password: ''
  })
  const [isSecondAuthenticating, setIsSecondAuthenticating] = useState(false)

  const { trigger: signIn, isMutating: isSigningIn } =
    useMutation('v1/auth/login')

  const handleLogin = (values: any) => {
    if (!isSigningIn) {
      signIn(
        {
          data: values
        },
        {
          onSuccess: () => {
            setCurrentSignIn({
              email: values.email,
              password: values.password
            })
            setIsSecondAuthenticating(true)
          },
          onError: (error) => {
            if (error?.code === ErrorNetwork) {
              notification.error({
                message: t('translation.networkError'),
                key: ErrorNetwork
              })
            } else {
              notification.error({ message: t('login.loginFailure') })
            }
          }
        }
      )
    }
  }

  const handleBackToLogin = () => {
    setIsSecondAuthenticating(false)
  }

  if (isSecondAuthenticating) {
    return (
      <SignInValidation
        currentSignIn={currentSignIn}
        handleBackToLogin={handleBackToLogin}
      />
    )
  }

  return (
    <AuthFormContainer>
      <Title title="login.title" />

      <Spinner spinning={isSigningIn}>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleLogin}
          autoComplete="off"
          className="custom-label"
          initialValues={{ remember: true }}
          requiredMark={false}
        >
          <Form.Item
            label={t('login.email')}
            name="email"
            className="mb-[30px]"
            rules={[{ validator: Validators.emailValidator(t) }]}
          >
            <Input
              autoComplete="new-email"
              size="large"
              placeholder={t('login.email')}
              maxLength={256}
            />
          </Form.Item>

          <Form.Item
            label={t('login.password')}
            name="password"
            className="mb-[30px]"
            rules={[{ validator: Validators.passwordValidator(t) }]}
          >
            <Input.Password
              autoComplete="new-password"
              size="middle"
              placeholder={t('login.password')}
              maxLength={256}
            />
          </Form.Item>

          <Button
            htmlType="submit"
            type="primary"
            block
            size="large"
            className="mb-[30px]"
          >
            {t('login.buttonLogin')}
          </Button>

          <Link
            to="/forgot_password"
            className="text-primary font-semibold flex justify-center underline mb-[30px] underline-offset-2"
          >
            {t('login.forgotPassword')}
          </Link>

          <Link
            to="/smz_signin"
            className="text-primary font-semibold flex justify-center underline underline-offset-2"
          >
            {t('login.shimizuSignIn')}
          </Link>
        </Form>
      </Spinner>
    </AuthFormContainer>
  )
}
