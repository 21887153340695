import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Typography } from 'antd'
import { Building } from 'models/Building'

import PreviewIcon from 'components/icons/PreviewIcon'

import Sidebar from '../Sidebar'
import UserDropdown from '../UserDropdown'

type Props = {
  building?: Building
}

export default function HeaderDetailBuilding({ building }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()

  const localStoringFloor = () => {
    floorId && localStorage.setItem('floor', floorId)
    window.open(`/viewer/buildings/${id}`, '_blank')
  }

  return (
    <div className="h-full grid grid-cols-3 items-center pl-5 bg-[#222222]">
      <div className="flex items-center gap-2 select-none">
        <Sidebar />

        <Link to="/buildings">
          <img
            src="/logo/transparent_header.png"
            alt="logo"
            className="max-w-[207px] cursor-pointer ml-[53px]"
          />
        </Link>
      </div>
      <div>
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: building?.organization?.name
          }}
          className="text-white text-center text-[14px] font-[500] truncate !mb-0"
        >
          {building?.organization?.name}
        </Typography.Paragraph>
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: building?.name
          }}
          className="text-white text-center truncate font-[500] !mb-0"
        >
          {building?.name}
        </Typography.Paragraph>
      </div>
      <div
        className="relative h-full flex justify-end gap-6"
        style={{
          backgroundImage: "url('/assets/background_header.png')"
        }}
      >
        <div
          className="h-[35px] my-auto grid place-items-center cursor-pointer"
          onClick={localStoringFloor}
        >
          <PreviewIcon />
          <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
            {t('common.header.preview')}
          </Typography>
        </div>

        <UserDropdown t={t} />
      </div>
    </div>
  )
}

HeaderDetailBuilding.defaultProps = {
  building: undefined
}
