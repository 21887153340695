import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DatePicker, Form, Input, Select, Typography } from 'antd'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import { Branch } from 'models/Branch'
import { ListOrgForListBuilding } from 'models/Building'
import { CurrentUser, Role } from 'models/User'
import useSWR from 'swr'
import { SearchUser } from 'types/listUser'
import Utils from 'utils'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import CalendarIcon from 'components/icons/Calendar'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

const { Option } = Select

type Props = {
  handleSearch: (value: SearchUser) => void
}

export default function FormSearch({ handleSearch }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const constructionPeriodStart = Form.useWatch('start_date', form)
  const constructionPeriodEnd = Form.useWatch('end_date', form)

  const { data: profile } = useSWR<CurrentUser | null>('v1/users/me', {
    revalidateOnMount: false
  })

  const { data: organizations } = useSWR<ListOrgForListBuilding>({
    url: `/v1/organizations-have-building`
  })

  const { data: branch } = useSWR(
    profile?.role === Role.SA
      ? {
          url: `/v1/branches/list-for-building`
        }
      : null
  )

  const listBranch = useMemo(
    () =>
      branch
        ? branch.map((item: Branch) => ({
            value: item.id,
            label: <span className="text-[#021120] text-sm">{item.name}</span>
          }))
        : [],
    [branch]
  )

  const disabledStartDate = (currentDate: any) => {
    return (
      constructionPeriodEnd &&
      dayjs(currentDate).format('YYYY-MM-DD') >=
        dayjs(constructionPeriodEnd).format('YYYY-MM-DD')
    )
  }

  const disabledEndDate = (currentDate: any) => {
    return (
      constructionPeriodStart &&
      dayjs(currentDate).format('YYYY-MM-DD') <=
        dayjs(constructionPeriodStart).format('YYYY-MM-DD')
    )
  }

  const onSearch = (values: any) => {
    const startDate = values.start_date
      ? dayjs(values.start_date).format('YYYY-MM-DD')
      : undefined
    const endDate = values.end_date
      ? dayjs(values.end_date).format('YYYY-MM-DD')
      : undefined

    handleSearch(
      Utils.trimObjectValues({
        ...values,
        start_date: startDate,
        end_date: endDate
      })
    )
    form.setFieldsValue(Utils.trimObjectValues(values))
  }

  return (
    <Form
      layout="horizontal"
      className="font-medium w-full"
      onFinish={onSearch}
      form={form}
      colon={false}
    >
      <div className="flex flex-wrap gap-5">
        <Form.Item
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('user.keyword')}
            </Typography>
          }
          name="keyword"
          className="flex items-center mb-0 label-custom-select thin-placeholder thin-error-message"
          rules={[{ validator: Validators.keywordValidator(t) }]}
        >
          <Input className="min-w-[180px] max-w-[180px] h-[38px] text-sm" />
        </Form.Item>
        <Form.Item
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('building.organizationNameSearch')}
            </Typography>
          }
          name="organization_id"
          className="flex items-center mb-0 label-custom-select thin-placeholder"
        >
          <Select
            placeholder={t('building.placeholder')}
            className="min-w-[180px] max-w-[180px] h-[38px]"
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
          >
            <Option value="">&nbsp;</Option>
            {organizations &&
              organizations.edges.map((org) => (
                <Option key={org.id} value={org.id}>
                  <span className="text-sm">{org.name}</span>
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="flex items-center mb-0 label-custom-select thin-placeholder"
          label={
            <Typography className="text-[14px] leading-[21px] text-[#021120]">
              {t('building.status')}
            </Typography>
          }
          name="status"
        >
          <Select
            placeholder={t('building.placeholder')}
            className="min-w-[180px] max-w-[180px] h-[38px]"
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
          >
            <Option value="">&nbsp;</Option>
            {Const.STATUS_BUILDING.map((status) => (
              <Option key={status} value={status}>
                <span className="text-sm">{t(`building.${status}`)}</span>
              </Option>
            ))}
          </Select>
        </Form.Item>

        {profile?.role === Role.SA && (
          <>
            <Form.Item
              className="flex items-center mb-0 label-custom-select thin-placeholder"
              label={
                <Typography className="text-[14px] leading-[21px] text-[#021120]">
                  {t('building.branchNameInfo')}
                </Typography>
              }
              name="branch_id"
            >
              <Select
                placeholder={t('building.placeholder')}
                className="min-w-[180px] max-w-[180px] h-[38px]"
                suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
              >
                <Option value="">&nbsp;</Option>
                {listBranch.map((item: any) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="mx-auto flex items-center gap-3">
              <Form.Item
                className="flex items-center mb-0 label-custom-select thin-placeholder"
                label={
                  <Typography className="text-[14px] leading-[21px] text-[#021120]">
                    {t('building.constructionStart')}
                  </Typography>
                }
                name="start_date"
              >
                <DatePicker
                  placeholder=""
                  className="h-[38px] text-sm w-[160px]"
                  suffixIcon={<CalendarIcon />}
                  format="YYYY年MM月DD日"
                  disabledDate={disabledStartDate}
                />
              </Form.Item>
              〜
              <Form.Item
                className="flex items-center mb-0 label-custom-select thin-placeholder"
                label={
                  <Typography className="text-[14px] leading-[21px] text-[#021120]">
                    {t('building.constructionEnd')}
                  </Typography>
                }
                name="end_date"
              >
                <DatePicker
                  placeholder=""
                  className="h-[38px] text-sm w-[160px]"
                  suffixIcon={<CalendarIcon />}
                  format="YYYY年MM月DD日"
                  disabledDate={disabledEndDate}
                />
              </Form.Item>
            </div>
          </>
        )}

        <Button
          type="primary"
          sizing="w-[160px] h-[40px]"
          className="ml-auto"
          htmlType="submit"
        >
          {t('user.buttonSearch')}
        </Button>
      </div>
    </Form>
  )
}
